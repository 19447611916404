import React, { useState } from "react";
import { Link } from "react-router-dom";
import filesFetcher from '../Services/filesFetcher';
import CloseIcon from '@mui/icons-material/Close';

let offerAmount = null;

const isKitchenApp = process.env.REACT_APP_NAME === 'kitchens';
const isBathroomApp = process.env.REACT_APP_NAME === "bathrooms";
function convertToISO(dateStr) {
  const [month, day, year] = dateStr.split("-");
  const fullYear = parseInt(year, 10) < 50 ? `20${year}` : `19${year}`;
  return `${fullYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  // convertToISO(date)
}

const getOfferData = async (setOffer) => {
  const offersSchedule = await filesFetcher.fetchPromoOffer();
  let offers = [];
  const today = new Date();
  const scheduleDates = Object.keys(offersSchedule);
  let currentDateSchedule = null;

  for (let date of scheduleDates) {
    const dateSchedule = new Date(convertToISO(date));
 
    if (today <= dateSchedule) {
      if (!currentDateSchedule) {
        offers = offersSchedule[date];
        currentDateSchedule = dateSchedule;

      } else if (dateSchedule < currentDateSchedule) {
        offers = offersSchedule[date];
        currentDateSchedule = dateSchedule;
      }
    }
  };

  if (offers && !offers.length) {
    let lastScheduled = offersSchedule[scheduleDates[scheduleDates.length - 1]];
    offers = lastScheduled;
  }

  if (offers && offers.length) {
    const offerData = offers.find((item) => item.value === (isKitchenApp ? "Kitchen" : isBathroomApp ? "Bathroom" : 'Basements'));

    if (offerData) {
      offerAmount = offerData.banner;
      setOffer(offerData.banner);
    }
  }
};

export const Banner = () => {
    
  const [offer, setOffer] = useState(offerAmount);
  
  if (!offer) {
    getOfferData(setOffer);
    return null;
  }

  return (
    <div className="promoStamp">
      <button 
        onClick={(e)=> { 
          e.currentTarget.parentElement.style.display = 'none'; 
          e.currentTarget.parentElement.parentElement.classList.add('closedPromo'); }} 
        className="closeBtn"
      >
        <CloseIcon/>
      </button>
      <p className="topPar">
        <span>
          Save {offer.amount} on {isKitchenApp ? 'Kitchen': isBathroomApp ? "Bathroom" : 'Basement'} Renovations
        </span>
      </p>

      <p className="middleOffer desk">
        <strong>No</strong> Money Down <br/>
        <strong>No</strong> Interest <br/>
        <strong>No</strong> Payments for 1 Year*
      </p>
      <p className="middleOffer mobile">
        <strong>No</strong> Money Down, <strong>No</strong> Interest, <strong>No</strong> Payments for 1 Year*
      </p>


          {offer.extra 
                ? <p 
                  style={{ textAlign: 'center', paddingTop: '10px' }} 
                  className={(offer.extra.substring(0, 1) === '$') ? "middleOffer image extra desk" : "middleOffer image desk"}
                >
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
          {offer.extra 
                ? <p style={{ textAlign: 'center', paddingTop: '10px' }} className="middleOffer image mobile">
                  <strong>{
                    (offer.extra.substring(0, 1) === '$') ? `Take an extra ${offer.extra} OFF your project` : offer.extra
                  }</strong>
                  </p> 
                : null 
              }
    
          <Link sx={{ color: '#fff', fontWeight: 700 }} to="/get-started?offer=1" className="blue-dark-windows-btn">
            <div style={{ color: '#fff', fontWeight: 700 }} className="button">
              See Offer
            </div>
          </Link>

      <span className="endPart">Offer ends {offer.end}</span>
    </div>
  );
};