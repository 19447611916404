import React, { Component, useState } from "react";
import { Link } from "react-router-dom";

import Stations from "../../Common/Stations";
import StationsUS from "../../Common/StationsUS";
import { SliderText } from "./SliderText";
import VideoModal from "../../Common/videoModal";
import { Banner } from '../../Common/promoBanner';

const isBathrooms = process.env.REACT_APP_NAME === 'bathrooms';

export class TopSection extends Component {
  
  constructor (props) {
    super()
    this.state = {
      isOpen: false,
      countryCode: props.location?.countryCode,
    }
    this.videos = {
      laptopVideo: this.state.countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
      HGTV: '5kVBooRtR4Q',
    };
    this.openModal = this.openModal.bind(this)
    this.handleHGTVStar = this.handleHGTVStar.bind(this)
    this.Stantions = null
  }

  componentDidUpdate() {
    const countryCode = this.props.location?.countryCode;

    if (countryCode && this.state.countryCode !== countryCode) {
      this.setState({ countryCode });
      this.videos = {
        laptopVideo: countryCode === 'CA' ? '0_Rr23cnD0Q' : 'TeXeepjTWKA',
        HGTV: '5kVBooRtR4Q',
      };
    }
  }

  openModal () {
    this.setState({ isOpen: true, videoId: this.videos.laptopVideo })
  }

  handleHGTVStar () {
    this.setState({ isOpen: true, videoId: this.videos.HGTV })
  }
  

  render() {
    const { title, subtitle, listTitle, listItems, backgroundImage, className, children, noPhoto } = this.props;
    const groupColor = (localStorage.getItem("ABTGroup") === 'B' || window.ABLabel === 'B')  ? 'ctaB' : 'cta';
    
      return (
        <>
          <section 
           className={`home-section ${className ? className : ''}`} 
           style={{ marginTop: '-220px' }}
          >
            <div className="banner-cover" />
            <section className="home-banner">
              <div className={isBathrooms ? "welcome-text bath" : "welcome-text"}>
                <h1>{title}</h1>
                <p className="welcome-sub">{subtitle}</p>
                <h4>{listTitle}</h4>
                <ul>
                  {listItems && listItems.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
                <Link to="/get-started" className={`blue-btn ${groupColor} desktop-btn`}>Free Consultation</Link>
              </div>
              { this.state.isOpen && 
                  <VideoModal
                    isOpen={this.state.isOpen}
                    videoId={this.state.videoId}
                    handleClose={ ()=>{ this.setState({ isOpen: false }); } }
                  />
              }

            {/* {noPhoto ? null : (
              <div className="footerInfoBar" style={{ marginRight: '2vw' }}>
                <div className="itemInfo">
                  <b className="border">Ivan A.</b>
                  <span className="border">CEO AGM Renovations</span>
                </div>
                <div onClick={this.handleHGTVStar} className="itemInfo hovered" style={{ paddingLeft: 0, marginLeft: '2vw'}}>
                  <b>Mike Holmes</b>
                  <span  style={{ padding: 0 }} ><strong>HGTV</strong> Star & Contractor</span>
                   <hr/>
                </div>
              </div>
            )} */}

            </section>
            {children}

            <Banner/>

          </section>

    
          <SliderText showButton={false} />
          {this.state.countryCode === 'CA' 
            ? (<Stations/>)
            : (< StationsUS/>)
          }
        </>
      )

  }
}
export default TopSection;